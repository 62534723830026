import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import * as Styled from './slpCouponsContentListStyle';

const SlpCouponsContentList = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (
          <Styled.ImageStyle src={asset.node.file.url} alt={asset.node.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  const LeftData = sectionData.list[0];
  const RightData = sectionData.list[1];

  return (
    <Styled.BodyContainer>
      <Styled.Body>
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        <Styled.RowStyle>
          <Styled.ColumnStyle>
            {LeftData &&
              documentToReactComponents(
                JSON.parse(LeftData?.contentDetails?.raw),
                optionsMainStyle
              )}
          </Styled.ColumnStyle>
          <Styled.OrDiv>or</Styled.OrDiv>
          <Styled.ColumnStyle>
            {RightData &&
              documentToReactComponents(
                JSON.parse(RightData?.contentDetails?.raw),
                optionsMainStyle
              )}
          </Styled.ColumnStyle>
        </Styled.RowStyle>
      </Styled.Body>
    </Styled.BodyContainer>
  );
};
export default SlpCouponsContentList;
